<template>
  <div id="table">
    <h1>{{ title }}</h1>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12">
          <table>
            <thead>
              <tr>
                <th>
                  Team
                </th>
                <th>
                  Dominator
                </th>
                <th>
                  Slaughterer
                </th>
                <th>
                  Creditor
                </th>
                <th>
                  Warmonger
                </th>
                <th>
                  Power Broker
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="standings in standing" :key="standings.id">
                <td>{{ standings.name }}</td>
                <td>{{ standings.dominator }}</td>
                <td>{{ standings.slaughterer }}</td>
                <td>{{ standings.creditor }}</td>
                <td>{{ standings.warmonger }}</td>
                <td>{{ standings.powerBroker }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventService from "../services/EventService";
export default {
  data() {
    return {
      standing: null,
      title: "Current Standings"
    };
  },
  created() {
    // fetch event by id and set local data
    this.getTableData();
  },
  methods: {
    getTableData() {
      EventService.getStanding()
        .then(response => {
          this.standing = response.data;
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  computed: {}
};
</script>
<style lang="scss" scoped>
body {
  background-color: grey;
}
#table {
  padding-bottom: 2em;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  h1 {
    color: #0438ad;
    padding: 1.5rem 0;
    text-align: center;
    font-weight: 900;
  }
  table {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
      0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
      0 16px 16px rgba(0, 0, 0, 0.12);
    background: #ffffff;

    tr {
      transition: ease-in-out;
      &:nth-of-type(even) {
        background: #ececec;
      }
      &:hover,
      &:active {
        background: #066d04;
        border: 2px solid #066d04;
        &th:hover,
        &th:active {
          color: #ffffff;
        }
      }
      th {
        font-size: 1.2em;
        color: #fff;
        line-height: 1.4;
        text-transform: uppercase;
        background-color: #36304a;
        max-width: 100%;
        width: 20em;
        padding: 0.5em 1em;
        cursor: pointer;
        &:active {
          cursor: progress;
        }

        &:hover {
          background-color: #484848 !important;
        }
      }
      td {
        font-size: 1.2em;
        color: #808080;
        line-height: 1.4;
        padding: 1em 0;
        &:hover,
        &:active {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
