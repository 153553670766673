import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://my-json-server.typicode.com/lumber520/NecroFunda",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

export default {
  getGames() {
    return apiClient.get("/games");
  },
  getGame(id) {
    return apiClient.get("/games/" + id);
  },
  getGangs() {
    return apiClient.get("/gangs/");
  },
  getStanding() {
    return apiClient.get("/standings/");
  },
  getNews() {
    return apiClient.get("/vox/");
  },
  getWanted() {
    return apiClient.get("/wanted/");
  }
};
