<template>
  <div v-if="news" id="news" class="transition-up">
    <div class="container">
      <h2 @click="showHideNews">{{ title }}</h2>
      <transition name="fade">
        <div v-if="showNews === true" class="news-items">
          <span @click="showHideNews">X</span>
          <ul>
            <li v-for="item in news" :key="item.id">{{ item.news }}</li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import EventService from "../services/EventService";
import { ref } from "vue";
export default {
  data() {
    return {
      news: null
    };
  },
  created() {
    EventService.getNews()
      .then(response => {
        this.news = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  setup() {
    const title = ref("Vox News");
    let showNews = ref(true);
    function showHideNews() {
      showNews.value = !showNews.value;
    }

    return { title, showNews, showHideNews };
  }
};
</script>

<style scoped lang="scss">
#news {
  background: #f6e4a9;
  padding: 1em 0;
  color: #000000;
  animation: transition-up 1.2s;
  h2,
  span {
    cursor: pointer;
  }
  .news-items {
    li {
      text-align: left;
    }

    span {
      position: absolute;
      top: 1%;
      left: 96%;
      font-size: 1.5em;
      font-weight: 900;
    }
  }
  .fade-leave-active {
    transition: opacity 2s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transition: all 1s ease;
    transform: translateY(20px);
  }
  .fade-enter-active {
    transition: all 1s ease;
    transform: translateY(20px);
  }
}

@keyframes transition-up {
  from {
    opacity: 0;
    transform: translateY(-105%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
